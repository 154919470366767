<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <!-- <a-spin :spinning="confirmLoading">
            <table class="mytable">
                <tr>
                    <td class="title">商家ID</td>
                    <td>{{details.storeId}}</td>
                    <td class="title">商家昵称</td>
                    <td>{{details.storeName}}</td>
                </tr>
                <tr>
                    <td class="title">缴纳金额</td>
                    <td>{{details.price}}</td>
                    <td class="title">支付方式</td>
                    <td>{{details.payType==1?'支付宝':details.payType==2?'微信':details.payType==3?'余额':''}}</td>
                </tr>
                <tr>
                    <td class="title">审核状态</td>
                    <td :style="{color:details.result==1?'orange':details.result==2?'green':'red'}">
                        {{details.result==1?'待审核':details.result==2?'已通过':details.result==3?'已拒绝':''}}
                    </td>
                    <td class="title">支付状态</td>
                    <td>
                        {{
                            details.payStatus==1?'待支付':
                            details.payStatus==2?'支付成功':
                            details.payStatus==3?'支付失败':
                            details.payStatus==4?'退还':''
                        }}
                    </td>
                </tr>
                <tr>
                    <td class="title">审核备注</td>
                    <td colspan="3">{{details.refuseReason}}</td>
                </tr>
            </table>
        </a-spin> -->
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <!-- 第1行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="商家ID">
                                    <span>{{details.storeId}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="商家昵称">
                                    <span>{{details.storeName}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="缴纳金额">
                                    <span>{{details.price}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="支付方式">
                                    <span>{{details.payType==1?'支付宝':details.payType==2?'微信':details.payType==3?'余额':''}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第3行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="审核状态">
                                    <span :style="{color:details.status==1?'orange':details.status==2?'green':'red'}">
                                        {{details.status==1?'待审核':details.status==2?'已通过':details.status==3?'已拒绝':''}}
                                    </span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="支付状态">
                                    <span>
                                        {{
                                            details.payStatus==1?'待支付':
                                            details.payStatus==2?'支付成功':
                                            details.payStatus==3?'支付失败':
                                            details.payStatus==4?'退还':''
                                        }}
                                    </span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row v-if="details.status==2 || details.status==3">
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="审核备注">
                                    <span>{{details.refuseReason}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { bondAuditDetail } from '@/api/modular/mallLiving/bondMoney'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            previewVisible: false,
            previewVisible1: false,
            id:'',
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                bondAuditDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 身份证正面大图显示与隐藏
        preVisible(){
            this.previewVisible = true;
        },
        Cancelpreview(){
            this.previewVisible = false;
        },
        // 身份证反面大图显示与隐藏
        preVisible1(){
            this.previewVisible1 = true;
        },
        Cancelpreview1(){
            this.previewVisible1 = false;
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
        height: 50px;
    }
</style>